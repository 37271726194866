import React from 'react';
import styled from 'styled-components';
import { SdirFooter, SdirHeader, styles } from '@sdir/sds';
import { useIntl } from 'react-intl';

const AppLayout: React.FC = ({ children }) => {
  const intl = useIntl();
  return (
    <>
      <SdirHeader
        appName={intl.formatMessage({ id: 'home.page.title' })}
        backLink={{
          url: 'https://sdir.no',
          text: intl.formatMessage({ id: 'userbar.sdirlink' }),
        }}
        maxContentWidth="1490px"
      />
      <AppContainer data-testid="verify-app-container">{children}</AppContainer>
      <SdirFooter tagline={intl.formatMessage({ id: 'footer.tagline' })} maxContentWidth="1490px" />
    </>
  );
};

export default AppLayout;

const AppContainer = styled.div`
  background-color: ${styles.colors.background};
  margin: 0 auto 0 auto;
  max-width: 1490px;
  min-height: 700px;
  padding: 0 0 20px 0;

  @media screen and (max-width: ${({ theme }) => theme.screen.xLarge}) {
    padding: 0 15px 15px 15px;
  }

  footer ul {
    padding-left: 0;
  }
`;
