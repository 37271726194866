import React, { useCallback } from 'react';
import { FieldProps } from 'formik';
import { TextField } from '@sdir/sds';

interface FormInputProps extends FieldProps {
  field: any;
  label: string;
  type: any;
  hidenumberstyling?: number;
  tab: number;
  disabled: boolean;
  className?: string;
  value?: string | number | Date | undefined;
  onChangeCallback?: (val: any) => void;
  onBlurField?: () => void;
  novalidate?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({
  field,
  label,
  type,
  tab,
  disabled,
  className = '',
  value,
  onChangeCallback,
  form,
  onBlurField,
  novalidate,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (form?.setFieldValue) form.setFieldValue(field.name, e.target.value);
      if (onChangeCallback) onChangeCallback(e);
    },
    [form, field, onChangeCallback]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent) => {
      if (field?.onBlur) field.onBlur(e);
      if (onBlurField) onBlurField();
    },
    [field, onBlurField]
  );

  const meta = form.getFieldMeta(field.name);
  return (
    <div className={className}>
      <TextField
        {...field}
        disabled={disabled}
        id={field.name}
        tabIndex={tab}
        type={type}
        name={field.name}
        invalid={!!(form.errors[field.name] && form.touched[field.name] && !disabled)}
        value={field.value || value || ''}
        novalidate={novalidate}
        error={meta && meta.touched && meta.error}
        aria-label={label}
        label={label}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default FormInput;
