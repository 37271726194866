import { ErrorMessage } from '@sdir/utilities/lib/components';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ConvertCertificate } from '../../../helpers/ConvertCertificate';
import { personalCertificateVerifyApi } from '../../../httpclient';
import { CertificateView } from '../../Molecules';
import { CertificateViewProps } from '../../Molecules/CertificateView';

const CertificatePage: React.FC = () => {
  const { uid } = useParams<{ uid: string }>();
  const [searchError, setSearchError] = useState(null);
  const [certificate, setCertificate] = useState<CertificateViewProps | null>(null);

  useEffect(() => {
    personalCertificateVerifyApi
      .v1PersonalcertificateverifyVerifybyqrcodestringorguidIdentificatorGet(uid)
      .then((response) => {
        if (response.status === 200) {
          setCertificate(ConvertCertificate(response.data));
        }
      })
      .catch((error) => {
        setSearchError(error);
        setCertificate(null);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      {certificate && <CertificateView {...certificate} />}
      {searchError && <ErrorMessage error={searchError} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 2em;
`;

export default CertificatePage;
