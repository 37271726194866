import React from 'react';
import styled from 'styled-components';
import { styles } from '@sdir/sds';

const CardRow: React.FC<{ label: any; children: any; className?: string }> = ({
  label,
  children,
  className,
}) => {
  return (
    <Container className={className}>
      <div className="cardValue__label" data-testid="acr-label">
        {label}
      </div>
      <div className="cardValue__content" data-testid="acr-content">
        {children}
      </div>
    </Container>
  );
};

export default CardRow;

const Container = styled.div`
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }

  .cardValue__label {
    font-size: 14px;
    color: ${styles.colors.font.text85};
    font-family: ${styles.fonts.stfMedium};
  }

  .cardValue__content {
    color: ${styles.colors.font.text};
    font-family: ${styles.fonts.stfMedium};
    font-weight: bold;

    div,
    span {
      font-family: ${styles.fonts.stfMedium};
    }

    button {
      font-family: ${styles.fonts.stfBook};
    }
  }
`;
