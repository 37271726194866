import React from 'react';
import { AppVersionCheck } from '@sdir/utilities/lib/components';
import AppProviders from './components/Templates/AppProviders';
import Routes from './components/Routes';
import AppLayout from './components/Organisms/AppLayout/AppLayout';

const baseUrl = process.env.REACT_APP_VERCHECK_URL || window.location.href.split('#')[0];
const versionCheckInterval = process.env.REACT_APP_VERCHECK_INTERVAL
  ? +process.env.REACT_APP_VERCHECK_INTERVAL
  : null;

function App() {
  return (
    <AppProviders>
      <AppLayout>
        {versionCheckInterval && (
          <AppVersionCheck baseUrl={baseUrl} checkIntervalMs={versionCheckInterval} />
        )}
        <Routes />
      </AppLayout>
    </AppProviders>
  );
}

export default App;
