import React from 'react';
import { Switch, HashRouter, Route } from 'react-router-dom';
import { StandardRoutes } from '@sdir/auth/lib/components';
import { routes } from '../constants/routes';
import CertificatePage from './Pages/CertificatePage/CertificatePage';
import SearchForm from './Organisms';

const Routes: React.FC = () => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path={routes.certificateSearch} component={SearchForm} />
        <Route exact path={routes.home} component={SearchForm} />
        <Route exact path={routes.certificateByUid} component={CertificatePage} />
        <StandardRoutes />
      </Switch>
    </HashRouter>
  );
};

export default Routes;
