// eslint-disable-next-line max-len
import { PersonalCertificateVerificationResult } from '@sdir/httpclient/lib/clients/aps/personalcertificate';
import { CertificateViewProps } from '../components/Molecules/CertificateView';

export const ConvertCertificate = (
  certificateResponse: PersonalCertificateVerificationResult
): CertificateViewProps => {
  let name = '';
  if (certificateResponse.seafarerFirstName) name += `${certificateResponse.seafarerFirstName}`;
  if (certificateResponse.seafarerMiddleName) name += ` ${certificateResponse.seafarerMiddleName}`;
  if (certificateResponse.seafarerLastName) name += ` ${certificateResponse.seafarerLastName}`;
  const certificate: CertificateViewProps = {
    certificateType: certificateResponse.certificateType,
    certificateName: certificateResponse.certificateName,
    expiryDate: certificateResponse.certificateValidToDate || '',
    personName: name,
    certificateStatus: certificateResponse.status,
    certCode: certificateResponse.certificateCode || undefined,
    certNr: `${certificateResponse?.certificateNumber}` || '',
    validFrom: certificateResponse.certificateIssuedDate || '',
    issuedDate: certificateResponse.certificateIssuedDate || '',
  };
  return certificate;
};
